import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Interest} from '@sparbanken-syd/rt-backend'
import {BehaviorSubject, first, Observable} from 'rxjs'
import {LatestInterest, RtJson} from './types'
import {environment} from '../../environments/environment'
import {map, switchMap} from 'rxjs/operators'
import {GetUsageType, InterestType} from '../application/data-types'

@Injectable({
  providedIn: 'root'
})
export class InterestService {
  /**
   * Consumers should listen to this to get all that regards interests.
   */
  public interests$: BehaviorSubject<Interest[]> = new BehaviorSubject<Interest[]>([])
  public latestInterest$: BehaviorSubject<LatestInterest> = new BehaviorSubject<LatestInterest>({} as any)
  public interestTypes$: BehaviorSubject<InterestType[]> = new BehaviorSubject<InterestType[]>([{interestTypeKey: 'l753'} as any])

  /**
   * @param httpClient - To gain some https access.
   */
  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * get all interests from database
   */
  public getAllInterests(): Observable<Interest[]> {
    const url = `${environment.apiUrl}/interests`
    return this.httpClient.get<Interest[]>(url)
      .pipe(
        map((allInterests: Interest[]) => {
          let allInterestTypes: InterestType[] = []

          this.interestTypes$.pipe(first())
            .subscribe((interestTypes: InterestType[]) => {
              allInterestTypes = interestTypes
            })

          /**
           * First, check if any object has an approvedTime property
           */
          if (allInterests.some(i => i.approvedTime)) {
            /**
             * Then, we must check that interestStartDate is not in the future and that the
             * interest is approved. And we also need to check that there is a case for this
             */

            let valid: Interest | null = null


            /**
             * iterate all interest types to make the latest one valid
             */
            allInterestTypes.forEach((interest: InterestType) => {
              const latestValidInterest = allInterests
                .filter(i => interest.interestTypeKey.includes(i.typeOfInterest))
                .filter((i) => (i.interestStartDate < new Date().getTime()) && i.approvedTime)
                .sort((a, b) => b.interestStartDate - a.interestStartDate)[0]

              if (latestValidInterest) {
                valid = latestValidInterest
                valid.latestValid = true
              }
            })
          }

          /**
           * Sort by created
           */
          allInterests.sort((a: Interest, b: Interest) => b.timeStamp - a.timeStamp)
          this.interests$.next(allInterests)
          return allInterests
        })
      )
  }

  /**
   * Get the latest valid interest from database
   */
  public getLatestInterest(typeOfInterest: string): Observable<LatestInterest> {
    const url = `${environment.apiUrl}/interests/latest/${typeOfInterest}`
    return this.httpClient.get<LatestInterest>(url)
      .pipe(
        map((interest: LatestInterest) => {
          this.latestInterest$.next(interest)
          return interest
        })
      )
  }

  /**
   * Get disclaimer text from backend.
   */
  public getDisclaimerText(p: number, a: number, iR: number, sF: number, vI: number): Observable<string> {
    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'text/plain')

    const url =
      `${environment.apiUrl}/interests/disclaimer?periods=${p}&amount=${a}&interestRate=${iR}&startFee=${sF}&interestStartDate=${vI}`
    return this.httpClient.get(url, {headers, responseType: 'text'})
      .pipe(
        map((disclaimer: string) => disclaimer)
      )
  }

  /**
   * Get disclaimer json with more info about effective interest from backend.
   */
  public getDisclaimerJson(p: number, a: number, iR: number, sF: number, vI: number): Observable<RtJson> {
    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'application/json')

    const url =
      `${environment.apiUrl}/interests/disclaimer?periods=${p}&amount=${a}&interestRate=${iR}&startFee=${sF}&interestStartDate=${vI}`

    return this.httpClient.get<RtJson>(url, {headers})
      .pipe(
        map((disclaimer: RtJson) => disclaimer)
      )
  }

  /**
   * Save/add a new interest to database
   */
  public addInterests(interest: Interest): Observable<Interest[]> {
    const url = `${environment.apiUrl}/interests`
    return this.httpClient.put<Interest>(url, interest)
      .pipe(
        switchMap(() => this.getAllInterests())
      )
  }

  /**
   * Save/update an existing interest to database
   */
  public updateInterests(interest: Interest): Observable<Interest[]> {
    const url = `${environment.apiUrl}/interests/${interest.itemId}`
    return this.httpClient.put<Interest>(url, interest)
      .pipe(
        switchMap(() => this.getAllInterests())
      )
  }

  /**
   * Approve an existing interest to database
   */
  public approveInterests(interest: Interest, itemId: string): Observable<Interest[]> {
    const url = `${environment.apiUrl}/interests/${interest.itemId}/approve`
    return this.httpClient.put<Interest>(url, {interest, itemId})
      .pipe(
        switchMap(() => this.getAllInterests())
      )
  }

  /**
   * Delete a interest from database
   */
  public deleteInterest(id: string): Observable<void> {
    const url = `${environment.apiUrl}/interests/${id}`
    return this.httpClient.delete<void>(url)
  }

  /**
   * get all interest types from database
   */
  public getAllInterestTypes(): Observable<InterestType[]> {
    const url =
      `${environment.apiUrl}/interest-types`

    return this.httpClient.get<InterestType[]>(url)
      .pipe(
        map((interestTypes: InterestType[]) => {
          this.interestTypes$.next(interestTypes)
          return interestTypes
        })
      )
  }

  /**
   * Save/update an existing interest type to database
   */
  public updateInterestType(interestType: InterestType): Observable<InterestType[]> {
    const url = `${environment.apiUrl}/interest-types/${interestType.itemId}`
    return this.httpClient.put<InterestType>(url, interestType)
      .pipe(
        switchMap(() => this.getAllInterestTypes())
      )
  }

  /**
   * Get information about usage of API KEYS
   *
   * startDate - The starting date (e.g., 2022-01-01) of the usage data.
   * endDate - The ending date (e.g., 2022-12-31) of the usage data.
   * keyId - The id of the API key associated with the resultant usage data.
   * usagePlanId - The id of the usage plan associated with the usage data.
   */
  public getKeysInformation(keyId: string, startDate: string, endDate: string): Observable<any> {

    const url = `${environment.apiUrl}/keys?keyId=${keyId}&startDate=${startDate}&endDate=${endDate}`
    return this.httpClient.get<GetUsageType>(url)
      .pipe(
        map((usageData: GetUsageType) => usageData)
      )
  }
}
